/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Therapieverfahren"), "\n", React.createElement(_components.p, null, "In Deutschland sind als Therapieverfahren die Psychoanalyse, die tiefenpsychologisch fundierte Psychotherapie und die Verhaltenstherapie zugelassen."), "\n", React.createElement(_components.p, null, "Alle diese Verfahren können als Einzelbehandlung oder in der Gruppe erfolgen."), "\n", React.createElement(_components.p, null, "Als weitere Methode innerhalb der genannten Verfahren ist inzwischen auch EMDR zugelassen, wobei dies ein Einzelsetting erfordert."), "\n", React.createElement(_components.p, null, "Für alle Therapieverfahren gilt, dass moderne neurobiologische Erkenntnisse dazu geführt haben, dass die Vertreter der verschiedenen therapeutischen Verfahren und Methoden voneinander zunehmend lernen und sich in mancher Hinsicht aufeinander zu bewegen."), "\n", React.createElement(_components.p, null, "In der Praxis wird daher oft Bewährtes aus verschiedenen Schulen kombiniert."), "\n", React.createElement(_components.p, null, "Auch eine gleichzeitige Behandlung mit Medikamenten – zusätzlich zur Psychotherapie – ist oft hilfreich, muss aber in jedem Einzelfall abgewogen werden."), "\n", React.createElement(_components.p, null, "Gezielte Wissensvermittlung (Edukation) sowie ein ressourcenorientiertes Erarbeiten individueller Möglichkeiten erweitern heute praktisch jedes Behandlungskonzept."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
